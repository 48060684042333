function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { GALLERY_CONSTS } from 'pro-gallery';
export var LAYOUT_ORIENTATION_TYPES = {
  vertical: 'VERTICAL',
  // 0
  horizontal: 'HORIZONTAL' // 1

};
export var presets = {
  // PRESET 1
  1: {
    id: 1,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.horizontal,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '1,2v,2v';
      res.cubeImages = true;
      res.cubeRatio = '50%/100%';
      res.imageMargin = 0;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.titlePlacement = 'SHOW_ON_HOVER';
      res.cubeType = 'crop';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '1,2v,2v';
      res.cubeImages = true;
      res.imageMargin = 0;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.titlePlacement = 'SHOW_ON_HOVER';
      res.cubeType = 'crop';
      return res;
    }
  },
  // PRESET 2
  2: {
    id: 2,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '1,2v,2v,2v,2v,1';
      res.cubeImages = true;
      res.cubeRatio = 0.75;
      res.groupsPerStrip = 3;
      res.imageMargin = 5;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.enableInfiniteScroll = true;
      res.cubeType = 'crop';
      res.isVertical = false;
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.groupSize = 2;
      res.rotatingGroupTypes = '1,2h,2h';
      res.cubeImages = true;
      res.rotatingCropRatios = 0.75;
      res.fixedColumns = 1;
      res.imageMargin = 2;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      return res;
    }
  },
  3: {
    id: 3,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.horizontal,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.groupSize = 1;
      res.cubeImages = true;
      res.cubeRatio = '100%/50%';
      res.rotatingCropRatios = '25%/100%,50%/100%';
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.isVertical = true;
      res.cubeType = 'crop';
      res.calculateTextBoxHeightMode = 'AUTOMATIC';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.cubeImages = false; // Slider fixed styles

      res.groupSize = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.isVertical = false;
      res.groupTypes = '1';
      res.enableInfiniteScroll = true;
      res.isSlider = true;
      return res;
    }
  },
  4: {
    id: 4,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.groupSize = 1;
      res.cubeImages = true;
      res.cubeRatio = 4 / 3;
      res.fixedColumns = 1;
      res.imageMargin = 0;
      res.gridStyle = 1;
      res.titlePlacement = 'ALTERNATE_HORIZONTAL';
      res.textBoxWidthPercent = 35;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.hoveringBehaviour = 'NO_CHANGE';
      res.cubeType = 'crop';
      res.imageInfoType = 'NO_BACKGROUND';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.titlePlacement = 'SHOW_BELOW';
      res.cubeImages = true; // Grid fixed styles;

      res.isVertical = true;
      res.groupSize = 1;
      res.hasThumbnails = false;
      res.groupTypes = '1';
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.isGrid = true;
      return res;
    }
  },
  5: {
    id: 5,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.horizontal,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '2v';
      res.cubeImages = true;
      res.rotatingCropRatios = '25%/40%,25%/60%,25%/60%,25%/40%';
      res.imageMargin = 2;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.titlePlacement = 'SHOW_ON_HOVER';
      res.cubeType = 'crop';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.groupTypes = '2v';
      res.rotatingGroupTypes = '2v';
      res.cubeImages = true;
      res.rotatingCropRatios = '50%/35%,50%/65%,50%/65%,50%/35%';
      res.imageMargin = 2;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.titlePlacement = 'SHOW_ON_HOVER';
      return res;
    }
  },
  6: {
    id: 6,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.horizontal,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '2v,2v';
      res.imageMargin = 150;
      res.rotatingScatter = '-50%/80%,7%/70%,-280%/-220%,100%/-100%,-150%/-50%';
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.titlePlacement = 'SHOW_ON_HOVER';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.cubeImages = false;
      res.arrowsPosition = 1;
      res.titlePlacement = 'SHOW_BELOW'; // Slideshow fixed styles

      res.hoveringBehaviour = 'NEVER_SHOW';
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.galleryMargin = 0;
      res.isVertical = false;
      res.groupSize = 1;
      res.groupTypes = '1';
      res.isSlideshow = true;
      return res;
    }
  },
  7: {
    id: 7,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.groupSize = 1;
      res.cubeImages = true;
      res.fixedColumns = 1;
      res.imageMargin = 80;
      res.gridStyle = 1;
      res.titlePlacement = 'SHOW_ON_THE_RIGHT';
      res.textBoxWidthPercent = 66;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.imageInfoType = 'NO_BACKGROUND';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.groupSize = 1;
      res.cubeImages = true;
      res.fixedColumns = 1;
      res.imageMargin = 20;
      res.galleryMargin = 10;
      res.gridStyle = 1;
      res.titlePlacement = 'SHOW_ON_THE_RIGHT';
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.imageInfoType = 'NO_BACKGROUND';
      return res;
    }
  },
  8: {
    id: 8,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.groupTypes = '1,2v';
      res.rotatingGroupTypes = '2v,1,2v';
      res.cubeImages = true;
      res.groupsPerStrip = 3;
      res.imageMargin = 20;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.isVertical = false;
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.groupTypes = '2h,1';
      res.rotatingGroupTypes = '2h,1';
      res.cubeImages = true;
      res.groupsPerStrip = 1;
      res.imageMargin = 10;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.gallerySize = 60;
      res.cubeType = 'crop';
      res.titlePlacement = 'SHOW_ON_HOVER';
      return res;
    }
  },
  9: {
    id: 9,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '3t,3b';
      res.cubeImages = true;
      res.rotatingCropRatios = '1.41,.7,.7,.7,.7,1.41';
      res.groupsPerStrip = 2;
      res.imageMargin = 5;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.isVertical = false;
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.groupTypes = '2h,1';
      res.rotatingGroupTypes = '1,2h';
      res.cubeImages = true;
      res.groupsPerStrip = 1;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.titlePlacement = 'SHOW_ON_HOVER';
      return res;
    }
  },
  10: {
    id: 10,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.rotatingGroupTypes = '1,2v,1,1';
      res.cubeImages = true;
      res.rotatingCropRatios = '1.6,1.2,1.2,1.2,1.6';
      res.groupsPerStrip = 2;
      res.imageMargin = 5;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.isVertical = false;
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.groupTypes = '2h,1';
      res.rotatingGroupTypes = '1,2h';
      res.cubeImages = true;
      res.groupsPerStrip = 1;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      res.titlePlacement = 'SHOW_ON_HOVER';
      return res;
    }
  },
  11: {
    id: 11,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.minItemSize = 80;
      res.groupTypes = '2h,3h';
      res.rotatingGroupTypes = '2h,3h';
      res.collageDensity = 0;
      res.cubeImages = true;
      res.fixedColumns = 1;
      res.imageMargin = 80;
      res.galleryMargin = 80;
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      res.cubeType = 'crop';
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.titlePlacement = 'SHOW_BELOW'; // Slideshow fixed styles

      res.hoveringBehaviour = 'NEVER_SHOW';
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.galleryMargin = 0;
      res.isVertical = false;
      res.groupSize = 1;
      res.groupTypes = '1';
      res.isSlideshow = true;
      return res;
    }
  },
  12: {
    id: 12,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.rotatingGroupTypes = '2v,2v';
      res.fixedColumns = 3;
      res.imageMargin = 100;
      res.galleryMargin = 100;
      res.rotatingScatter = '-50%/-40%,0%/-10%,40%/100%';
      res.gridStyle = 1;
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.VERTICAL;
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.cubeImages = false;
      res.arrowsPosition = 1;
      res.titlePlacement = 'SHOW_BELOW'; // Slideshow fixed styles

      res.hoveringBehaviour = 'NEVER_SHOW';
      res.scrollDirection = GALLERY_CONSTS.scrollDirection.HORIZONTAL;
      res.galleryMargin = 0;
      res.isVertical = false;
      res.groupSize = 1;
      res.groupTypes = '1';
      res.isSlideshow = true;
      return res;
    }
  },
  13: {
    id: 13,
    layoutOrientation: LAYOUT_ORIENTATION_TYPES.vertical,
    desktop: function desktop(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.groupSize = 1;
      res.cubeImages = true;
      res.rotatingCropRatios = '0.7,1.77,0.9,1.77,1.77,0.7,0.9';
      res.fixedColumns = 2;
      res.imageMargin = 97;
      res.gridStyle = 1;
      res.titlePlacement = 'SHOW_BELOW';
      res.scrollDirection = 0;
      return res;
    },
    mobile: function mobile(styles) {
      var res = _objectSpread({}, styles);

      res.isVertical = true;
      res.groupSize = 1;
      res.cubeImages = true;
      res.rotatingCropRatios = '0.7,1.77,0.9,1.77,1.77,0.7,0.9';
      res.fixedColumns = 2;
      res.imageMargin = 97;
      res.gridStyle = 1;
      res.titlePlacement = 'SHOW_BELOW';
      res.scrollDirection = 0;
      return res;
    }
  }
};
export var addCustomPresetStyles = function addCustomPresetStyles(_ref) {
  var styles = _ref.styles,
      presetId = _ref.presetId,
      _ref$isMobile = _ref.isMobile,
      isMobile = _ref$isMobile === void 0 ? false : _ref$isMobile;

  if (isMobile) {
    var _presets$presetId;

    return ((_presets$presetId = presets[presetId]) == null ? void 0 : _presets$presetId.mobile(styles)) || styles;
  } else {
    var _presets$presetId2;

    return ((_presets$presetId2 = presets[presetId]) == null ? void 0 : _presets$presetId2.desktop(styles)) || styles;
  }
};